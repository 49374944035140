module.exports = [{
      plugin: require('/home/gp2mv3/Programmation/remindme/landing/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gp2mv3/Programmation/remindme/landing/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gp2mv3/Programmation/remindme/landing/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gp2mv3/Programmation/remindme/landing/node_modules/gatsby-plugin-transition-link/gatsby-browser'),
      options: {"plugins":[]},
    }]
